
import { Container, Row, Col } from 'react-bootstrap';
import Section_1_Img from '../Sources/Images/Backgrounds/Section_1_Img.png';
import Section_2_Img from '../Sources/Images/Backgrounds/Section_2_Img.png';
import Section_3_Img from '../Sources/Images/Backgrounds/Section_3_Img.png';

function Body() {
    return (
        <Container>
            <main>
                <section className="section_1 slanted slant-right">
                    <Row className="align-items-end">
                        <Col xs={12} lg={6} className="order-lg-1 order-2">
                            <img className="img-fluid" src={Section_1_Img}></img>
                        </Col>
                        <Col xs={12} lg={6} className="my-auto content_area order-lg-2 order-1">
                            <Row className="m-0">
                                <Col xs={12} lg={11}>
                                    <h2 className="mb-3">
                                        <span className="fw-light">SUCCEEDING</span><br />
                                        <span className="fw-bold">AGAINST</span><br />
                                        <span className="fw-bold">THE ODDS</span>
                                    </h2>
                                    <p>Online gambling is a popular pursuit. In fact, there are nearly 32 million online betting accounts currently active in the UK.</p>

                                    <p className="highlight fw-bold">But spending beyond your means can cause more than money problems for you and your family.</p>

                                    <p className="fw-bold">Gambling can lead to debt, family breakdown and mental health problems.</p>

                                    <p className="fw-bold">It might also result in you coming into contact with loan sharks and others engaged in illegal activities.</p>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section className="slanted slant-left section_2">
                    <Row className="align-items-end">
                        <Col xs={12} lg={6} className="my-auto content_area">
                            <Row className="m-0">
                                <Col xs={12} lg={11} className="offset-lg-1">
                                    <h2 className="mb-3">
                                        <span className="fw-light">TAKE</span><br />
                                        <span className="fw-bold">THE TEST</span>
                                    </h2>
                                    <p className="fw-bold">Are you concerned that you might have a problem with gambling? </p>
                                    <p>
                                        'If so, click on the NHS link below. You do not have to provide any personal details.
                                    </p>
                                    <a className="btn" target="_blank" href="https://www.gamcare.org.uk/understanding-gambling-problems/self-assessment-tool/">GAMBLING QUESTIONNAIRE</a>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6}>
                            <img className="img-fluid" src={Section_2_Img}></img>
                        </Col>
                    </Row>
                </section>

                <section className="section_3 slanted slant-right">
                    <Row className="align-items-end">
                        <Col xs={12} lg={6} className="my-auto content_area">
                            <Row className="m-0">
                                <Col lg={11} className="offset-lg-1">
                                    <h2 className="mb-3">
                                        <span className="fw-bold">TACKLING THE </span><br />
                                        <span className="fw-bold">ISSUE</span><br />
                                        <span className="fw-light">CAN BE</span><br />
                                        <span className="fw-light">DIFFICULT</span>
                                    </h2>
                                    <p>Reducing gambling harms is a lot more complicated than telling people to stop. </p>

                                    <p>
                                        There are many reasons why people gamble including easy access, time and money, poverty and the power of advertising.
                                    </p>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} lg={6} className="content_area">
                            <Row className="m-0 justify-content-center">
                                <Col xs={6}>
                                    <img className="img-fluid pt-4 pb-4" src={Section_3_Img}></img>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>


                <section className="section_4 slanted slant-left">
                    <Row>
                        <Col xs={12} className="my-auto content_area">
                            <Row className="m-0">
                                <Col xs={12} className="text-center">
                                    <h2 className="mb-3">
                                        <span className="fw-light">THINGS CAN</span><br />
                                        <span className="fw-bold">IMRPOVE</span>
                                    </h2>
                                    <p className="mb-5 mt-4">Although problems with gambling are serious, they can be overcome. Here are a list of organisations that can help both you and your loved ones: </p>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={4}>
                                            <p>
                                                <a target="_blank" className="btn w-100" href="https://beaconcounsellingtrust.co.uk/">BEACON COUNSELLING TRUST</a>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={4}>
                                            <p>
                                                <a target="_blank" className="btn w-100" href="https://www.gamcare.org.uk/">GAMCARE</a>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={4}>
                                            <p>
                                                <a target="_blank" className="btn w-100" href="https://www.leedsandyorkpft.nhs.uk/our-services/northern-gambling-service/referrals-contacting-us/">NHS NORTHERN GAMBLING SERVICE</a>
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xs={12} lg={4}>
                                            <p>
                                                <a target="_blank" className="btn w-100" href="https://www.gamblersanonymous.org.uk/">GAMBLERS ANONYMOUS</a>
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

            </main>
        </Container>
    );
}

export default Body;