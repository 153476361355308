import AppHeader from './AppComponents/Header';
import AppBody from './AppComponents/Body';
import AppFooter from './AppComponents/Footer';

function App() {
    return (
        <div className="App">
            <AppHeader />
            <AppBody />
            <AppFooter />
        </div>
    );
}

export default App;
