import logo from '../Sources/Images/MVRP_Logo.png';

import twitterLogo from '../Sources/Images/TwitterLogo.png';
import instagramLogo from '../Sources/Images/InstagramLogo.png';
import facebookLogo from '../Sources/Images/FacebookLogo.png';

import knowsleyCouncilLogo from '../Sources/Images/KnowsleyCouncil.png';
import liverpoolCouncilLogo from '../Sources/Images/LCC.png';
import seftonCouncilLogo from '../Sources/Images/SeftonCouncil.png';
import stHelensCouncilLogo from '../Sources/Images/StHelensCouncil.png';
import wirralCouncilLogo from '../Sources/Images/Wirral.png';

import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Container>
            <footer className="pt-5">
                <Row className="justify-content-center mb-5 m-0">
                    <Col xs={12} lg={10}>
                        <Row className="justify-content-center">
                            <Col xs={9} lg={6}>
                                <p className="text-center text-lg-start">
                                    The Merseyside Violence Reduction Partnership brings together key partners such as Merseyside police, Merseyside Fire and Rescue, local government, National Probation Service and the county's Youth Offending Service, health and education professionals in a bid to reduce serious violence and prevent its causes.
                                    Visit <a className="underline hover fw-bold" href="https://merseysidevrp.com">merseysidevrp.com</a>
                                </p>
                            </Col>
                            <Col xs={9} lg={6}>
                                <Row className="justify-content-center">
                                    <Col xs={12} lg={7}>
                                        <p className="text-center text-lg-end">
                                            <a href="https://www.merseysidevrp.com/" target="_blank" className="default" aria-label="Link to Merseyside VRP main website">
                                                <img src={logo} className="img-fluid" alt="MVRP Logo" />
                                            </a>
                                        </p>
                                        <p className="text-center text-lg-start">
                                            <a href="https://twitter.com/MerseysideVrp" target="_blank" className="default me-3 mb-3 d-inline-block" aria-label="Link to Merseyside VRP Twitter">
                                                <img src={twitterLogo} width="36px" alt="Twitter Logo" />
                                            </a>
                                            <a href="https://www.instagram.com/p/CnT5vDSMFFc/" target="_blank" className="default me-3 mb-3 d-inline-block" aria-label="Link to Merseyside VRP Instagram">
                                                <img src={instagramLogo} width="36px" alt="Instagram Logo" />
                                            </a>
                                            <a href="https://www.facebook.com/MerseysideVRP/" target="_blank" className="default me-3 mb-3 d-inline-block" aria-label="Link to Merseyside VRP Facebook">
                                                <img src={facebookLogo} width="36px" alt="Facebook Logo" />
                                            </a>

                                            <a target="_blank" className="btn pt-1 pb-1 ps-3 pe-3" href="https://www.merseysidevrp.com/">CONTACT</a>
                                        </p>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <hr />
                <Row className="justify-content-center mb-4 m-0">
                    <Col xs={12} lg={9}>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={4} lg={2}>
                                <img src={seftonCouncilLogo} className="img-fluid p-3" alt="Sefton Council Logo" />
                            </Col>
                            <Col xs={4} lg={2}>
                                <img src={wirralCouncilLogo} className="img-fluid p-3" alt="Wirral Council Logo" />
                            </Col>
                            <Col xs={4} lg={2}>
                                <img src={liverpoolCouncilLogo} className="img-fluid p-3" alt="Liverpool Council Logo" />
                            </Col>
                            <Col xs={4} lg={2}>
                                <img src={knowsleyCouncilLogo} className="img-fluid p-3" alt="Knowsley Council Logo" />
                            </Col>
                            <Col xs={4} lg={2}>
                                <img src={stHelensCouncilLogo} className="img-fluid p-3" alt="St Helens Council Logo" />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="ps-3 pe-3">
                    <Col xs={12} lg={6} className="text-center text-lg-start">
                        <p>&copy; Merseyside Police - Violence Reduction Partnership. All Rights Reserved {currentYear}</p>
                    </Col>
                    <Col xs={12} lg={6} className="text-center text-lg-end">
                        <p>
                            <a target="_blank" href="https://www.merseyside.police.uk/hyg/terms-conditions/" className="default">Terms and conditions</a>
                            <span> - </span>
                            <a target="_blank" href="https://www.merseyside.police.uk/hyg/fpnmerseyside/privacy-notice/" className="default">Privacy policy</a>
                            <span> - </span>
                            <a target="_blank" href="https://www.merseyside.police.uk/hyg/fpnmerseyside/privacy-notice/" className="default">Cookie policy</a>
                        </p>
                    </Col>
                </Row>
            </footer>
        </Container>
    );
}

export default Footer;