import logo from '../Sources/Images/MVRP_Logo.png';
import { Container, Row, Col } from 'react-bootstrap';

function Header() {
    return (
        <header className="pb-5 pt-3">
            <Container>
                <Row>
                    <Col>
                        <h1 className="text-white m-0 p-0">
                            <a className="default" href="/">
                                <span className="fw-light">GAMBLING</span> <span className="fw-bold">HARMS</span>
                            </a>
                        </h1>
                    </Col>
                    <Col className="text-end">
                        <a href="https://www.merseysidevrp.com/" target="_blank" className="default" aria-label="Link to Merseyside VRP main website">
                            <img src={logo} height="50" alt="MVRP Logo" />
                        </a>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}

export default Header;